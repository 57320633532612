import { ProductListItem } from "./types";

/**
 * Replaces the productListItem values containing ,;| with a space character.
 *
 * This is only required for `productListItems`
 *
 * https://experienceleague.adobe.com/docs/analytics/implementation/vars/page-vars/products.html?lang=en
 *
 * @param {Array<string>} array
 * @returns {Array<string>} array
 */
export const sanitizeProductItem = (productListItem: ProductListItem) => {
  const invalidCharacterRegex = /[,;|]/g;

  for (const [productKey, productItem] of Object.entries(productListItem)) {
    if (Array.isArray(productItem)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      productListItem[productKey as keyof ProductListItem] = productItem.map(
        (item: string) =>
          item.replace(invalidCharacterRegex, " ").replace(/\s+/g, " ").trim(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
    } else if (typeof productItem === "string") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      productListItem[productKey as keyof ProductListItem] = productItem
        .replace(invalidCharacterRegex, " ")
        .replace(/\s+/g, " ")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .trim() as any;
    }
  }

  return productListItem;
};
